<template>
<div>
  <div class="play" :style="'height:' + playHeight + 'px'">
    <video class="video"
           id="video"
           :autoplay="false"
           :width="windowWidth"
           :height="playHeight"
           controls
           :src="info.videoUrl"
           type="video/mp4"/>
  </div>
</div>
</template>

<script>
export default {
name: "player",
  props: {
    info: {
      videoUrl: ''
    }
  },
  created() {
    if (this.info.videoUrl.slice(-4) === 'm3u8') {
      this.$nextTick(()=>{
        this.palym3u8(this.info.videoUrl)
      })
    }
  },
  data() {
    return {
      videoList: [{
        title:'文章标题',
        type:2,
        createAt:'2023-08-08',
        playHeight:200
      }],
      windowWidth: document.documentElement.clientWidth - 30,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      playHeight: 0,
      src: require('../assets/test.mp4'),
    }
  },
  mounted() {
    this.playHeight = this.windowWidth * (9 / 16)
  },
  methods:{
    palym3u8(src){
      let video = document.getElementById('video')
      if (video) {
        var Hls = window.Hls
        var hls = new Hls()
        var url = src
        if (Hls.isSupported()) {
          hls.loadSource(url)
          hls.attachMedia(video)
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            // video.play()
          })
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = url
          video.addEventListener('canplay', function () {
            // video.play()
          })
        }
        /*video.addEventListener("timeupdate",function(){
          var timeDisplay;
          var duration;
          //用秒数来显示当前播放进度
          timeDisplay = Math.floor(video.currentTime);
          duration = Math.floor(video.duration);
          if (timeDisplay>20)
          {
            emit('checkStatus',{name:'观看'})
          }
        },false);*/
      }
    }
  }
}
</script>

<style scoped>

</style>