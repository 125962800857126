<template>
  <div>
    <van-nav-bar
        title="回复详情"
        :border="false"
        left-arrow
        @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="content">
      <div class="task-detail">
         <div class="detail-top">
           <div @click="$router.push({path:'/user',query:{id:info.ext.id}})" v-if="info.ext" class="top-left">
                 <img v-lazyload :data-src="info.ext.headUrl" class="avatar" />
                  <div>
                    <h3>{{info.ext.nickName}}</h3>
                  </div>
           </div>
           <div @click="updateFocus" class="top-right">
             <van-icon color="#1989fa" name="plus" />
             关注
           </div>
         </div>
      </div>
      <div class="content-main">
         <div class="article">
           {{info.content}}
         </div>
         <div v-if="info.pics" class="article-img">
           <img v-lazyload :data-src="info.pics" class="avatar" />
         </div>
        <player v-if="info.videoUrl" :info="{videoUrl:info.videoUrl}"></player>
        <div v-for="(item, index) in info.childList" :key="index" class="comments-item">
          <img width="20" height="20" style="border-radius: 10px" v-lazyload :key="item.id" :data-src="item.ext.headUrl" />
          <div style="margin-left: 15px;flex: 1 1 0%;">
            <div class="comments-item-d1">{{ item.account }}</div>
            <div class="comments-item-d2">{{ item.content }}</div>
            <div class="comments-item-d3">
              <div class="comments-item-d3-l">
                {{ item.createTime }}
              </div>
              <div class="comments-item-d3-r">
                <span><van-icon size="16" name="chat-o" />{{item.clickCount}}</span>
                <span><van-icon size="16" name="like-o" />{{item.zanCount}}</span>
                <span v-if="!item.isPay" @click.stop="unlock(item)"><van-icon size="16" name="bag-o" />解锁</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskInfo} from "@/api/article";
import player from "@/components/player";
import {unlockReply, updateFave} from "@/api/user";
import {Toast} from "vant";
export default {
  name: "detail",
  components:{
    player
  },
  data() {
    return {
      info:{
        childList:[]
      }
    }
  },
  created() {
    this.getTaskInfo()
  },
  methods: {
       updateFocus(){
         Toast.loading({
           message: '加载中...',
           forbidClick: true,
         });
         updateFave({
           "contentId":this.info.id,
           "type": "1"
         }).then((res) => {
           if (res.code == 0) {
             this.getTaskInfo()
           }
         })
       },
    unlock(item){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      unlockReply({id:item.id}).then((res)=>{

      })
    },
    getTaskInfo(){
      getTaskInfo({id: this.$route.query.id}).then((res) => {
        if (res.code == 0) {
          this.info = res.result
        }
      })
    }
  },

}
</script>

<style scoped>
.des {
  font-size: 12px;
  padding-top: 13px;
  padding-left: 10px;
  letter-spacing: 1px;
  width: 47vw;
}

.des-name {
  font-size: 15px;
  color: rgba(0, 0, 0, .8);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.des-school {
  display: flex;
  padding-top: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, .5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.follow {
  float: right;
  margin-top: -28px;
  margin-right: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #0066ff;
}

.content {
  padding: 15px;
}

.content-title {
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 2px;
}

.content-main {

}

.content-time {
  font-size: 13px;
  color: rgba(0, 0, 0, .4);
  letter-spacing: 1px;
  padding: 20px 0;
}

.bottom-bar {
  width: 100%;
  height: 45px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  padding: 10px;
}

.bottom-bar-icon {
  transform: rotate(-90deg);
}

.s1 {
  font-size: 15px;
  letter-spacing: 2px;
  padding-left: 10px;
}

.comments-icon {
  float: right;
  padding-right: 5px;
  padding-top: 8px;
}

.comments-count {
  float: right;
  padding-right: 25px;
  color: #909399;
  letter-spacing: 1px;
  padding-top: 11px;
  font-size: 13px;
}

.popup-content {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 75vh;
}

.popup-content ::-webkit-scrollbar {
  display: none !important;
}

.popup-title {
  text-align: center;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: bold;
}

.popup-bottom {
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 99;
  background: #ffffff;
  bottom: 0;
  left: 0;
}

.popup-bottom-send {
  letter-spacing: 3px;
  float: right;
  color: #0066ff;
  line-height: 70px;
  font-weight: 400;
  font-size: 15px;
  padding-right: 15px;
}

.bottom-bar-input {
  height: 40px;
  font-size: 13px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, .4);
  margin-top: 13px;
  margin-left: 20px;
  width: 70%;
  border: none;
}

.comments-item {
  display: flex;
  margin-bottom: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
}

.comments-item-d1 {
  color: rgba(0, 0, 0, .9);
}

.comments-item-d2 {
  color: rgba(0, 0, 0, .7);
  padding: 4px 0;
}

.comments-item-d3 {
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  display: flex;
  justify-content: space-between;
}

.header .avatar {
  border-radius: 17.5px;
}

.task-detail{
  margin-bottom: 10px;
}

.task-detail .task-item {
  display: flex;
  line-height: 30px;
  position: relative;
}

.task-detail .task-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  content: '';
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-width: 1px 0 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
}

.task-detail .task-item .item-label {
  width: 30%;
}
.content-main .article{
  margin-bottom: 20px;
}
.detail-top{
 display: flex;
  justify-content: space-between;
}
.top-left{
  display: flex;
  align-items: center;
  gap: 10px;
}
.top-left .avatar{
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.top-left h3{
  margin: 0;
  font-size: 16px;
}
.top-right{
  color: #1989fa;
}

.comments-item {
  display: flex;
  margin-bottom: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
}

.comments-item-d1 {
  color: rgba(0, 0, 0, .9);
}

.comments-item-d2 {
  color: rgba(0, 0, 0, .7);
  padding: 4px 0;
}

.comments-item-d3 {
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  display: flex;
  justify-content: space-between;
}

.header .avatar {
  border-radius: 17.5px;
}

.task-detail{
  margin-bottom: 10px;
}

.task-detail .task-item {
  display: flex;
  line-height: 30px;
  position: relative;
}

.task-detail .task-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  content: '';
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-width: 1px 0 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
}

.task-detail .task-item .item-label {
  width: 30%;
}
.more{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, .4);
}
.comments-item-d3-r span{
  margin-left: 10px;
}
.reply-wrap{
  border-top: 1px dashed #CDCDCD;
  padding: 10px 0;
  font-size: 14px;
}
.reply-wrap a{
  display: flex;
  align-items: center;
  gap: 5px;
}
.task-detail{
  margin-bottom: 10px;
}

.task-detail .task-item {
  display: flex;
  line-height: 30px;
  position: relative;
}

.task-detail .task-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  content: '';
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-width: 1px 0 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
}

.task-detail .task-item .item-label {
  width: 30%;
}
.content-main .article{
  margin-bottom: 20px;
}
.detail-top{
  display: flex;
  justify-content: space-between;
}
.top-left{
  display: flex;
  align-items: center;
  gap: 10px;
}
.top-left .avatar{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-top: 0;
}
.top-left h3{
  margin: 0;
  font-size: 16px;
}
.top-left .nickname{
  display: flex;
  align-items: center;
}
.top-right{
  color: #1989fa;
  display: flex;
  align-items: center;
}
</style>